var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"frontend-auth@1.0.325"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import errorService from '@razorpay/universe-cli/errorService/nextjs';

if (process.env.STAGE !== 'development' && process.env.UNIVERSE_PUBLIC_SENTRY_DSN) {
  errorService.init({
    dsn: process.env.UNIVERSE_PUBLIC_SENTRY_DSN,
    environment: process.env.UNIVERSE_PUBLIC_STAGE as string,
    version: `${__APP_NAME__}@${__VERSION__}`,
  });
}
